var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "gift-card-detail pb-0 pt-12 px-0",
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            {
              staticClass:
                "d-flex flex-column align-center justify-space-between",
              attrs: { xs12: "" }
            },
            [
              _vm.card
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column justify-center align-center text-center px-2"
                    },
                    [
                      _c("h2", { staticClass: "default--text" }, [
                        _vm._v(_vm._s(_vm.$t("giftCard.details.title")))
                      ]),
                      _c(
                        "div",
                        { staticClass: "qr-code-wrapper" },
                        [
                          _c("vue-qrcode", {
                            staticClass: "qr-code",
                            attrs: {
                              value: _vm.card.qrCode,
                              options: { width: 200 }
                            }
                          }),
                          _c("div", { staticClass: "card-info" }, [
                            _c("div", [
                              _vm._v(" Lotto card "),
                              _c("span", [_vm._v(_vm._s(_vm.card.lottoCard))])
                            ]),
                            _c("div", [
                              _vm._v(" Id card "),
                              _c("span", [_vm._v(_vm._s(_vm.card.idCard))])
                            ])
                          ])
                        ],
                        1
                      ),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("giftCard.details.description")))
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "bottom-section d-flex flex-column align-center grey lighten-1 text-center px-2 w-100 py-6 mt-3"
                },
                [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-img" }),
                    _c("div", { staticClass: "card-bottom" })
                  ])
                ]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }