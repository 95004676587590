var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "gift-card-report px-3 pt-12 px-0" },
    [
      _c("h2", { staticClass: "text-center default--text" }, [
        _vm._v(" " + _vm._s(_vm.$t("giftCard.reports.title")) + " ")
      ]),
      _c("div", { staticClass: "d-flex report-header mt-6" }, [
        _c("div", { staticClass: "w-50" }, [
          _vm._v(_vm._s(_vm.$t("giftCard.reports.date")))
        ]),
        _c("div", { staticClass: "flex-grow-1" }, [
          _vm._v(" " + _vm._s(_vm.$t("giftCard.reports.amount")) + " ")
        ])
      ]),
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "primary", height: "2" }
          })
        : _vm._e(),
      _vm._l(_vm.reports, function(report, index) {
        return _c(
          "div",
          { key: "report-" + index, staticClass: "d-flex report-row" },
          [
            _c("div", { staticClass: "w-50" }, [
              _vm._v(_vm._s(_vm.$dayjs(report.pdataop).format("DD/MM/YYYY")))
            ]),
            _c("div", { staticClass: "flex-grow-1 font-weight-semibold" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$n(parseFloat(report.valore), "currency")) +
                  " "
              )
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }