var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "pa-0 gift-cards-page" }, [
    _vm.cards.length > 0
      ? _c(
          "div",
          [
            _c("div", { staticClass: "pa-2 mb-2" }, [
              _c("h4", { staticClass: "text-uppercase" }, [
                _vm._v(_vm._s(_vm.$t("giftCard.card.title")))
              ]),
              _c("div", [_vm._v(_vm._s(_vm.$t("giftCard.card.description")))])
            ]),
            _c(
              "v-row",
              { staticClass: "grey lighten-1 pa-2 mb-6" },
              _vm._l(_vm.cards, function(card, index) {
                return _c(
                  "v-col",
                  {
                    key: "card-" + index,
                    staticClass:
                      "d-flex justify-center align-center flex-column",
                    attrs: { cols: "12", sm: "6", md: "4" }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass: "gift-card",
                        on: {
                          click: function($event) {
                            return _vm.showDetails(card)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "top-part pa-3",
                            class:
                              card.disponibile == "0" ? "expired" : "active"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("giftCard.card.valid", {
                                    startDate: _vm
                                      .$dayjs(card.attiva)
                                      .format("DD/MM/YYYY"),
                                    endDate: _vm
                                      .$dayjs(card.scadenza)
                                      .format("DD/MM/YYYY")
                                  })
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between align-center px-2 py-1"
                          },
                          [
                            _c("div", { staticClass: "text-body-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("giftCard.card.availableBalance")
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "text-h4" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$n(
                                      parseFloat(
                                        card.disponibile.replace(",", ".")
                                      ),
                                      "currency"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-2 w-100 d-flex justify-space-between flex-wrap"
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-decoration-underline px-1",
                            attrs: {
                              text: "",
                              color: "default",
                              depressed: "",
                              rounded: "",
                              small: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.openHistory(card.qrCode)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("giftCard.card.history")))]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-decoration-underline px-1",
                            attrs: {
                              text: "",
                              color: "default",
                              depressed: "",
                              rounded: "",
                              small: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.confirmRemoveCard(card.qrCode)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("giftCard.card.remove")))]
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "pa-2" },
      [
        _c("h4", { staticClass: "text-uppercase" }, [
          _vm._v(_vm._s(_vm.$t("giftCard.title")))
        ]),
        _c("div", { class: _vm.cards.length < 1 ? "text-body-0" : "" }, [
          _vm._v(" " + _vm._s(_vm.$t("giftCard.description")) + " ")
        ]),
        _c(
          "v-btn",
          {
            staticClass: "my-6",
            attrs: {
              color: "primary",
              depressed: "",
              rounded: "",
              "x-large": "",
              block: "",
              loading: _vm.loading
            },
            on: { click: _vm.scanCard }
          },
          [_vm._v(_vm._s(_vm.$t("giftCard.addCard")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }