import axios from "~/plugins/axios";
export default {
  getGiftCardlist() {
    let url = `/ebsn/api/giftCard/getList`;
    let deviceData = null;
    let config = {};
    // eslint-disable-next-line no-undef
    if (typeof device !== "undefined") {
      // eslint-disable-next-line no-undef
      deviceData = device;
    }

    if (deviceData) {
      config = {
        headers: {
          "X-Ebsn-Client-UUID": deviceData.uuid,
          "X-Ebsn-Client-Platform": deviceData.platform,
          "X-Ebsn-Client-Version": deviceData.version,
          "X-Ebsn-Client-Model": deviceData.model,
          "X-Ebsn-Client-Manufacturer": deviceData.manufacturer
        }
      };
    }

    return axios.get(url, null, config).then(data => {
      return data.data.data;
    });
  },
  getGiftCardReport(code) {
    let url = `/ebsn/api/giftCard/getReport?qrCode=${code}`;
    let deviceData = null;
    let config = {};
    // eslint-disable-next-line no-undef
    if (typeof device !== "undefined") {
      // eslint-disable-next-line no-undef
      deviceData = device;
    }

    if (deviceData) {
      config = {
        headers: {
          "X-Ebsn-Client-UUID": deviceData.uuid,
          "X-Ebsn-Client-Platform": deviceData.platform,
          "X-Ebsn-Client-Version": deviceData.version,
          "X-Ebsn-Client-Model": deviceData.model,
          "X-Ebsn-Client-Manufacturer": deviceData.manufacturer
        }
      };
    }

    return axios.get(url, null, config).then(data => {
      return data.data.data;
    });
  },
  addCard(code) {
    let url = `/ebsn/api/giftCard/add?qrCode=${code}`;
    let deviceData = null;
    let config = {};
    // eslint-disable-next-line no-undef
    if (typeof device !== "undefined") {
      // eslint-disable-next-line no-undef
      deviceData = device;
    }

    if (deviceData) {
      config = {
        headers: {
          "X-Ebsn-Client-UUID": deviceData.uuid,
          "X-Ebsn-Client-Platform": deviceData.platform,
          "X-Ebsn-Client-Version": deviceData.version,
          "X-Ebsn-Client-Model": deviceData.model,
          "X-Ebsn-Client-Manufacturer": deviceData.manufacturer
        }
      };
    }
    return axios
      .post(url, null, config)
      .then(response => response.data.data)
      .catch(err => err);
  },
  removeCard(code) {
    let url = `/ebsn/api/giftCard/remove?qrCode=${code}`;
    let deviceData = null;
    let config = {};
    // eslint-disable-next-line no-undef
    if (typeof device !== "undefined") {
      // eslint-disable-next-line no-undef
      deviceData = device;
    }

    if (deviceData) {
      config = {
        headers: {
          "X-Ebsn-Client-UUID": deviceData.uuid,
          "X-Ebsn-Client-Platform": deviceData.platform,
          "X-Ebsn-Client-Version": deviceData.version,
          "X-Ebsn-Client-Model": deviceData.model,
          "X-Ebsn-Client-Manufacturer": deviceData.manufacturer
        }
      };
    }
    return axios
      .post(url, null, config)
      .then(response => response.data.data)
      .catch(err => err);
  }
};
