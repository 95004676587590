<template>
  <v-container fluid fill-height class="gift-card-detail pb-0 pt-12 px-0">
    <v-layout>
      <v-flex
        xs12
        class="d-flex flex-column align-center justify-space-between"
      >
        <div
          v-if="card"
          class="d-flex flex-column justify-center align-center text-center px-2"
        >
          <h2 class="default--text">{{ $t("giftCard.details.title") }}</h2>
          <div class="qr-code-wrapper">
            <vue-qrcode :value="card.qrCode" :options="{ width: 200 }" class="qr-code"></vue-qrcode>
            
            <div class="card-info">
              <div>
                Lotto card <span>{{ card.lottoCard }}</span>
              </div>
              <div>
                Id card <span>{{ card.idCard }}</span>
              </div>
            </div>
          </div>

          <div>{{ $t("giftCard.details.description") }}</div>
        </div>
        <div
          class="bottom-section d-flex flex-column align-center grey lighten-1 text-center px-2 w-100 py-6 mt-3"
        >
          <div class="card">
            <div class="card-img"></div>
            <div class="card-bottom"></div>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.gift-card-detail {
  .qr-code-wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;
    background-image: url("/img_layout/giftCard/qr-code-wrap.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 60vw;
    max-width: 360px;
    aspect-ratio: 0.7347;
    .qr-code {
      position: absolute;
      width: 60% !important;
      height: auto !important;
      bottom: 17%;
      display: flex;
      justify-content: center;
      align-items: center;
      .qr-code-img {
        width: 60%;
      }
    }
    .card-info {
      position: absolute;
      width: 100%;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 5% 13%;
      font-size: 9px;
      span {
        font-weight: 600;
      }
    }
  }
  .bottom-section {
    .card {
      width: 365px;
      max-width: 80%;
      aspect-ratio: 1.632;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      overflow: hidden;
      margin-bottom: 8vh;
      .card-img {
        flex-grow: 1;
        background-image: url("/img_layout/giftCard/card.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
      }
      .card-bottom {
        height: 18%;
        width: 100%;
        background-color: $white;
      }
    }
  }
}
</style>

<script>
import matomoService from '@/service/analytics/matomoService';
export default {
  components: { },
  name: "GiftCardDetails",
  props: {
    card: { type: Object, required: true }
  },
  mounted(){
    matomoService.trackPageView("/giftCardDetails");
  },
  data() {
    return {};
  },
  methods: {},
  async created() {}
};
</script>
